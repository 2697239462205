.footer {
/*     background-color: #4c5c06; */
    background-color: #4d4537;
    color: #ffffff;
    text-align: center;
    height: 200px;   
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 60%;     /* This is making the info getting closer to each other. */
}

.contacts,
.privacy,
.argo {
    width: 33%;
}


@media (max-width: 768px) {

    .footer {        
        height: 250px;
        width: 100%;        
    }

    .footer-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;    
    }

    .contacts {
        width: 100%;
        justify-content: center;
    }
    .privacy {
        width: 100%;
        justify-content: center;
    }
    .argo  {
        width: 100%;
        justify-content: center;
        
    }

   


}