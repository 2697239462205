.aspect  {
  padding-top: 25%;

}

.carousel-control-prev, .carousel-control-next {
  pointer-events: none !important;

}

.carousel-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 80px;

}

.custom-carousel-controls {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: 350px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 25px 0px 0px 25px;
  z-index: 1;

}

.text-area {
  display: flex;
  align-items: top;
  justify-content: flex-start;
  height: 240px;
  width: 95%;
  overflow: auto;

}


.buttonsDiv{
  height: 55px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding-top: 8px;
}

.prevNextIcons{
  height: 45px;

}
@media (max-width: 480px) {
  .custom-carousel-controls { 
    width: 80%;
    height: fit-content;
    bottom: 20px;  
    font-size: 0.9rem;
    top: auto;
  }

  .text-area{
    margin-top: 5px;
    display: flex;
    align-items: top;
    justify-content: flex-start;    
    height: 170px;
    width: 95%;
    overflow: auto;
  }

}

@media (max-width: 768px) {

  .carousel-container{
    height: auto;
  }
  .aspect  {
    padding-top: 10%;
  }


  .custom-carousel-controls h1{
    height: 20px;
    font-size: 1.2rem;
    font-weight: 300;
  }
  .prevNextIcons{
    height: 35px;
  }
  .buttonsDiv{
    height: 40px;
    flex-direction: row;
    align-items: self-end;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }

}