.Feature{

    display: flex;
    justify-content: center;
}

.featureDiv{
    width: 12%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    margin-right: 3%;
    margin-left: 3%;
}
.h3{
    font-size: 18px;
}
@media (max-width: 768px) {

  .icontext{
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
    .h3{
        font-size: 14px;
      }
    .featureDiv {
        width: 50%;
        max-width: 234px;
        display: block;
        margin-bottom: 20px;
        margin-right: 5%;
        margin-left: 5%;
    }

    .icontext{
      font-size: 18px;
    }
}