section {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.image-1 {
    background-image: url('./images/gameview_auto_x2-transformed.jpeg');
}

.image-2 {
    background-image: url('./images/gameview33.jpg');
}



/*
.image-3 {
   background-image: url('./images/gameview2_auto_x2_colored_toned-transformed.jpeg');
  
}
*/



.image-4 {
    background-image: url('./images/first_auto-transformed22.jpeg');
}

.mainSec1 {
    display: flex;
    flex-direction: column;
    height: 100vh !important;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px !important;
    object-fit: cover;
}



.content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 100px;
}





.logo {
    display: flex;
    height: 220px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 35px;
}



.logoImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.logoImage img {
    width: 100%;
    max-width: 500px;
    /* min-width: 300px; */
}

.section1-gameLogo {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}






.section1-buttonContainer {
    display: flex;
    height: 30%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}



.section1-button1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    /* width: 300px; */
    margin-right: 60px;
    object-fit: fill;
}



.section1-button2 {

    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    /* width: 300px; */
    object-fit: fill;

}



.section1-button1 img {
    /* width: 300px; */
    height: 130px;
}



.section1-button2 img {
    /* width: 265px; */
    height: 100px;
}



.gbTruckDiv {
    height: 15%;
    max-width: 100%;
    position: static;
}





@media (width: 360px) and (height: 480px) {

    .logo {
        display: flex;
        height: 220px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
    }

}



@media (width: 1024px) and (height: 600px) {


      .section1-gameLogo {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section1-buttonContainer {
        top: -10px;
    }

}


@media (max-width: 768px) {

  
    .content-container {
        margin-top: 0px;
    }
    .logo,
    .section1-buttonContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section1-gameLogo {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section1-buttonContainer {
        top: 0;
        justify-content: center;
        width: 80%;
    }

    .section1-button1 {
        margin: 0;
    }

    .section1-button1 img {
        /* width: 100%; */
        height: 100px;
    }

    .section1-button2 img {
        /* width: 100%; */
        height: 77px;
    }

    .gbTruckDiv {
        height: 8%;
        max-width: 100%;
        position: static;
    }

}



********************************************** .image-4 {

    position: relative;

}