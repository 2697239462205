:root {
    --main-bg-color: #BBC7B3;
    /*background color for containers #b7b7a0*/
    --main-button-color: #97A191;
    /*background color for buttons #71705c*/
    --main-button-hover: #DFEDD5;
    /*background color for hover #495057*/
    --main-button-selected: #5B6157;
    /*background color for selected #495057*/
    --main-text-color: #5B6157;
}

/*PageLogo*/
.container {
    display: flex;
    justify-content: center; /* Horizontally centers the image */
    align-items: center;     /* Vertically centers the image */
    height: auto;         
  }
  
  .CMS-Logo {
    max-width: 75%;
    height: auto; /* Ensures the image scales properly */
    margin-top: 50px;
  }
/*PageLogo_ENd*/

/*Icons/images for "tilte images/icons*/
.category-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.category-title-image {
    text-align: center;
    margin-bottom: 20px;
}

.title-image {
    max-width: 100px;
    height: auto;
}

.search-container {
    display: flex;
    justify-content: center;
}

.search-input {
    padding: 6px;
    margin-top: 8px;
    font-size: 17px;
}

/* Container for data */
.element-data-container {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

/* Category button container */
.category-button-container {
    margin-bottom: 20px;
    padding: 10px;
    background-color: var(--main-bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

/* Grid for category buttons */
.category-button-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
}

/* Style for each category button */
.category-button {
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    background-color: var(--main-button-color);
    color: var(--main-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s, box-shadow 0.3s;
}

/* Hover effect for category button */
.category-button:hover {
    background-color: var(--main-button-hover);
}

/* Active (selected) category button */
.category-button.active {
    background-color: var(--main-button-selected);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
}

/* Container for expanded category details */
.category-details {
    margin-top: 20px;
    width: 100%;
}

/* Table for elements */
.element-table {
    width: 100%;
    border-collapse: collapse;
    border: none;
}

/* Style for table cells */
.element-table th,
.element-table td {
    padding: 8px;
    border: none;
}

/* Button styling with expanded state */
.full-width-button {
    width: 100%;
    background-color: var(--main-bg-color);
    color: #5B6157;
    border: none;
    border-radius: 12px;
    padding: 15px;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    position: relative;
    /* Needed for positioning of the background images */
}

/* Hover effect for full-width button */
.full-width-button:hover {
    background-color: var(--main-button-hover);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Style for expanded state of button */
.full-width-button.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Expanded row style */
.expanded-row {
    width: 100%;
    margin-bottom: 16px;
}

/* Container for expanded element */
.expanded-element-container {
    padding: 15px;
    background-color: var(--main-bg-color);
    border-radius: 0 0 12px 12px;
    /* Matches button border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: -17px;
    /* Overlap with button */
    width: 100%;
    /* Ensures alignment with button width */
}

/* Style for each expanded element item */
.expanded-element-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

/* Input field styling */
.element-input {
    
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add-save-buttons {
    display: flex;
    gap: 10px;
}

/* Add Field button styling */
.add-field-button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.add-field-button:hover {
    background-color: #0056b3;
}

.add-field-button:active {
    transform: scale(0.98);
}

/* Save Element button styling */
.save-element-button {
    background-color: #28a745;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.save-element-button:hover {
    background-color: #218838;
}

.save-element-button:active {
    transform: scale(0.98);
}

/* Remove Field button styling */
.remove-field-button {
    background-color: #dc3545;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s, transform 0.3s;
}

.remove-field-button:hover {
    background-color: #c82333;
}

.remove-field-button:active {
    transform: scale(0.98);
}

/* Remove button container */
.remove-button-container {
    margin-left: auto;
}

/* Remove Element button styling */
.remove-element-button {
    background-color: #dc3545;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    
}

/* Hover effect for Remove Dialogue button */
.remove-element-button:hover {
    background-color: #c82333;
}

/* Active effect for Remove Dialogue button */
.remove-element-button:active {
    transform: scale(0.98);
}

.edit-element-button {
    background-color: #ffde05; /* Yellow color */
    color: white; /* Ensure text is visible against the yellow background */
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-right: 10px;
}

.edit-element-button:hover {
    background-color: #e6c904; /* Slightly darker yellow for hover effect */
}

.edit-element-button:active {
    transform: scale(0.98); /* Slight click feedback */
}


/* Container for action buttons - Align buttons horizontally */
.button-actions {
    display: flex;
    gap: 10px;
    /* Space between buttons */
    align-items: center;
    /* Align buttons vertically centered */
    margin-top: 10px;
}

.icon-button {
    background-color: transparent; /* Default background */
    color: white; /* Default color for the icon */
    border: 2px solid transparent; /* Border for outline effect */
    border-radius: 50%; /* Circular border */
    padding: 0; /* Remove default padding */
    cursor: pointer;
    display: flex;
    align-items: center; /* Center icon vertically */
    justify-content: center; /* Center icon horizontally */
    transition: background-color 0.3s, border-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    width: 40px; /* Size of the button */
    height: 40px; /* Size of the button */
    font-size: 20px; /* Icon size */
    position: relative; /* Needed for tooltip positioning */
    box-sizing: border-box; /* Include padding and border in the element's width and height */
    line-height: 40px; /* Match this to the button's height */
}

.icon-button i {
    width: 20px;
    height: 20px;
    margin: 0; /* Remove any default margins */
    padding: 0; /* Ensure no extra padding */
    line-height: 1; /* Prevent any height discrepancies */
}

/* General button styles */
.icon-button.save-element-button {
    opacity: 1; /* Full opacity when enabled */
    transition: opacity 0.3s ease; /* Smooth transition effect */
}

/* When the button is disabled */
.icon-button.save-element-button[disabled] {
    opacity: 0.1; /* Reduced opacity when disabled */
    cursor: not-allowed; /* Optional: change cursor to indicate it's disabled */
}



/* Tooltip styling */
.icon-button::after {
    content: attr(data-tooltip);
    /* Content from data-tooltip attribute */
    position: absolute;
    bottom: 125%;
    /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    /* Dark background for tooltip */
    color: white;
    /* White text color */
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    /* Prevent text wrapping */
    opacity: 0;
    /* Hidden by default */
    visibility: hidden;
    /* Hidden by default */
    transition: opacity 0.3s;
    z-index: 10;
    /* Ensure tooltip is above other elements */
    line-height: 1;
}

/* Show tooltip on hover */
.icon-button:hover::after {
    opacity: 1;
    /* Make tooltip visible */
    visibility: visible;
    /* Make tooltip visible */
}

/* Default color for specific icon buttons */
.remove-field-button.icon-button {
    background-color: transparent;
    /* Transparent background */
    color: #dc3545;
    /* Red icon color for default state */
}

/* Specific hover effect for Remove Field button */
.remove-field-button.icon-button:hover {
    background-color: #dc3545;
    /* Red background on hover */
    color: white;
    /* White icon color on hover */
    border-color: #dc3545;
    /* Red border on hover */
}

/* Default color for icon buttons */
.save-element-button.icon-button {
    background-color: #28a745;
    /* Green background for Save */
    border-color: #28a745;
    /* Green border for default state */
}

.remove-element-button.icon-button {
    background-color: #dc3545;
    /* Red background for Remove */
    border-color: #dc3545;
    /* Red border for default state */
}

.add-field-button.icon-button {
    background-color: #007bff;
    /* Blue background for Add */
    border-color: #007bff;
    /* Blue border for default state */
}

.edit-element-button.icon-button {
    background-color: transparent;
    color: #4f4f4f;
}

.edit-element-button.icon-button:hover {
    background-color: #ff9f05;
    /* Yellow background on hover */
    color: white;
    /* White icon color on hover */
    border-color: #ffde05;
    /* Yellow border on hover */
}

/* Hover effect for icon buttons */
.icon-button:hover {
    background-color: white;
    /* White background on hover */
    color: inherit;
    /* Change icon color to match the button's background */
    border-color: transparent;
    /* Remove border on hover */
}

/* Specific hover effects for each button type */
.save-element-button.icon-button:hover {
    color: #28a745;
    /* Green icon on hover */
}

.remove-element-button.icon-button:hover {
    color: #dc3545;
    /* Red icon on hover */
}

.add-field-button.icon-button:hover {
    color: #007bff;
    /* Blue icon on hover */
}

.add-object-button-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.add-object-button {
    padding: 5px 15px;
    background-color: blue;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
}

.add-object-button:hover {
    background-color: #f1f1f1;
    color:#0066ff;
}

/* Styling for the plus icon */
.add-icon {
    margin-right: 8px; /* Space between the icon and the text */
    font-size: 24px; /* Adjust icon size */
    /*filter: drop-shadow(1px 1px 0 blue);*/ /* Add a shadow effect with the same color */
    display: inline-block; /* Ensure the transform applies correctly */
}

/* Universal non-editable input field style */
.input-not-editable {
    background-color: #e2f0d9;
    border: 1px solid #d2e9d2;
    color: #333; /* Text color */
    cursor: not-allowed; /* Change cursor to indicate non-interactive */
    pointer-events: none; /* Prevent any mouse interactions */
}

/* Additional styles for specific types */
.textarea-not-editable {
    height: auto; /* Allow auto height adjustment */
    resize: none; /* Prevent resizing */
}

  



/*Unikke styles for de individuelle komponenter*/


/*Dialogue data*/
.dialogue-preview-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.dialogue-preview-modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    width: 1750px; /* Match the width from Unity */
    height: 250px;  /* Match the height from Unity */
    cursor: pointer; /* Add pointer cursor for click-to-close */
}

.dialogue-preview-wrapper {
    position: relative; /* Allows absolute positioning of text */
    height: 100%; /* Use full height of the modal content */
}

.dialogue-preview-image {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    object-fit: contain; /* Maintain aspect ratio */
}

.dialogue-preview-text {
    position: absolute; /* Overlay text on the image */
    top: 50%; /* Start from the middle */
    left: 2.5%; /* Adjust for padding */
    right: 4.8%; /* Adjust for padding */
    transform: translateY(-50%); /* Center text vertically */
    color: black;
    font-size: 2.6vw; /* Match font size in Unity */
    line-height: 1.2; /* Adjust line height for readability */
    text-align: left; /* Align text left */
    overflow-wrap: break-word; /* Ensure long words break */
    white-space: normal; /* Allow text to wrap */
    padding: 2%; /* Add padding for better spacing */
}

.tooltip-container {
    position: relative;
}

.tooltip-text {
    visibility: visible;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s;
    font-size: 20px;
    white-space: nowrap;
    width: 100%; /* gør så hele input feltet fyldes med tooltip beskeden */
    pointer-events: none;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}


/* Modal Styles */
.dialogue-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.dialogue-modal-content {
    background-color: var(--main-bg-color);
    border-radius: 12px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Modal Header */
.dialogue-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

/* Modal Body */
.dialogue-modal-body {
    margin-bottom: 15px;
}

.dialogue-modal-footer {
    display: flex;
    justify-content: flex-end;
}

/* Input Fields */
.dialogue-modal-textarea {
    width: 88%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 3%;
}

.dialogue-modal-addbutton {
    margin-left: 3%;
}

/*Chapters*/
.chapter-container {
    padding: 20px;
    margin: 15px 0;
    background-color: var(--main-bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.chapter-header {
    font-size: 24px; /* Adjust size for prominence */
    font-weight: bold; /* Make the title bold */
    color: var(--main-text-color); /* Dark gray color for better contrast */
    margin-bottom: 16px; /* Space below the header */
    text-align: center; /* Center align the header text */
    padding-bottom: 8px; /* Space between the text and the border */
    position: relative; /* Needed for positioning the pseudo-element */
}

/* Adding a border under the header */
.chapter-header::after {
    content: '';
    display: block; /* Make it a block element */
    width: 100%; /* Full width */
    height: 2px; /* Height of the border */
    background: linear-gradient(to right, var(--main-bg-color) 0%, var(--main-text-color) 50%, var(--main-bg-color) 100%);
    position: absolute; /* Position it relative to the header */
    left: 0; /* Align to the left */
    bottom: 0; /* Align to the bottom */
}

/* Container for the edit mission data */
.edit-mission-data-container {
    background-color: var(--main-bg-color);
    border: 1px solid #ddd;
    border-radius: 0 0 12px 12px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: -17px;
}

/* Ensure that each field's label and input are aligned */
.edit-mission-field {
    display: flex;
    flex-direction: column; /* Stack label above input */
    margin-bottom: 12px;
}

/* Label is placed above the input field */
.edit-mission-field label {
    margin-bottom: 6px;
    font-weight: bold;
}

/* Input fields take up the full width of the container */
.edit-mission-field input,
.edit-mission-field textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 40px;
}

/* Full-width fields */
.edit-mission-field.full-width {
    width: 100%;
}

/* Two-column layout for remaining fields */
.two-column-layout {
    display: flex;
    gap: 20px; /* Space between columns */
}

/* Left column for smaller fields */
.left-column {
    flex: 1; /* Take up remaining space */
}

/* Right column for larger fields and checkboxes */
.right-column {
    flex: 1; /* Take up remaining space */
}

/* Flex container for checkboxes */
.checkbox-container {
    display: flex;
    flex-direction: row; /* Align checkboxes side by side */
    gap: 20px; /* Space between checkboxes */
    margin-top: 16px; /* Space from above fields */
}

/* Checkbox field styling */
.checkbox-field {
    display: flex;
    flex-direction: column; /* Stack label above checkbox */
    width: 50%; /* Adjust width if needed */
}

.checkbox-field input[type="checkbox"] {
    width: 30px; /* Set width of checkbox */
    height: 30px; /* Set height of checkbox */
}

/* Container for item names */
.item-names-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the entire block */
}

/* Container for each item dropdown and buttons */
.item-dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
}

/* Wrapper for dropdown and remove button */
.mission-dropdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align dropdown and remove button to the left */
}

/* Label styling */
.item-dropdown-container label {
    font-weight: bold;
    margin-bottom: -10px;
    margin-left: 15px;
    text-align: left;
    align-self: flex-start; /* Align the label with the left edge of the dropdown */
}

/* Dropdown styling */
.mission-dropdown .form-select {
    width: auto;
    min-width: 200px;
    display: block;
    box-sizing: border-box;
}

.item-dropdown.disabled {
    pointer-events: none; /* Prevent clicks */
    opacity: 0.5; /* Make it appear grayed out */
}

/* Remove button styling */
.icon-button.custom-removefield {
    position: relative;
    margin-top: 8px;
    margin-left: -10px;
}

/* Add button styling */
.icon-button.custom-addfield {
    align-self: flex-start; /* Align the add button with the left edge of the dropdown */
    margin-top: -5px;
    margin-left: 15px;
}

/* Create Mission Modal Styles */
.create-mission-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    z-index: 2000;
}

.create-mission-modal-content {
    background-color: var(--main-bg-color);
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    max-width: 800px; /* Increased max-width for better layout */
    max-height: 90vh; /* Prevent overlap with screen edges */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
    border: 1px solid transparent; /* Prevent scrollbar from affecting borders */
    position: relative; /* For positioning scrollbar margin */
}

.create-mission-modal-header {
    display: flex;                   /* Enable flexbox for alignment */
    justify-content: space-between;  /* Space out header elements */
    align-items: center;             /* Center items vertically */
    width: 100%;                     /* Full width of the modal */
}

.centered-fields div {
    margin-bottom: 10px; /* Space between each input */
}

/* Align labels to the left and make them bold */
label {
    display: block; /* Ensure labels occupy full width */
    margin-bottom: 5px; /* Space between label and input */
    text-align: left; /* Align text to the left */
    font-weight: bold; /* Make labels bold */
}

/* Input Fields for Create Mission Modal */
.create-mission-modal-textarea {
    width: 100%; /* Full width for better usability */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Scrollbar */
.create-mission-modal-content::-webkit-scrollbar {
    width: 8px;
}

.create-mission-modal-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Color for the scrollbar thumb */
    border-radius: 4px; /* Rounded edges for the scrollbar */
}

.create-mission-modal-content::-webkit-scrollbar-track {
    background: transparent; /* Background for the scrollbar track */
}



/* Container for each question, similar to edit-mission-data-container */
.edit-question-data-container {
    background-color: var(--main-bg-color);
    /* Matches mission background color */
    border: 1px solid #ddd;
    /* Same border style */
    border-radius: 0 0 12px 12px;
    /* Matches button border radius */
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: add shadow for visual depth */
    margin-top: -17px;
    /* Adjust if necessary */
}

/* Ensure that each field's label and input are aligned */
.edit-question-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    
}

/* Label takes up 20% of the container width */
.edit-question-field label {
    margin-right: 12px;
    font-weight: bold;
    align-self: flex-start; /* Align label to the start */
}

/* Input fields take up the remaining space */
.edit-question-field input,
.edit-question-field textarea {
    flex: 1;
    /* Remaining space */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

}

/* Larger textarea for Explanation */
.edit-question-field.explanation textarea {
    width: 100%;
    height: 100px;
    /* Increase height to fit more text */
    resize: vertical;
    /* Allow resizing vertically */
}

/* New container for Answer and Choices */
.answer-choices-container {
    display: flex; /* Use flexbox for horizontal alignment */
    justify-content: row; /* Space items evenly */
    gap: 15%;
}

/* Each field in the row takes up 48% of the width */
.answer-choices-container .edit-question-field {
    flex: 0 0 25%;
}

.answer-choices-container .edit-question-field:nth-child(2) {
    flex: 0 0 25%; 
}


/* Optional: Style for the select element to match inputs */
.answer-choices-container .input-with-button select {
    width: 100%;
    padding: 8px; /* Consistent padding */
    border: 1px solid #ccc; /* Same border style */
    border-radius: 4px; /* Same border radius */
    box-sizing: border-box; /* Include padding and border in width */
}


.form-select {
    width: 17%;
    display: inline-block;
}

.edit-mission-field input[type="checkbox"] {
    width: 20px;
    /* Increase the size of the checkbox */
    height: 20px;
    /* Increase the size of the checkbox */
    margin-right: 75%;
    vertical-align: middle;
    /* Aligns checkbox vertically with text */
}

/* Container Styling */
.data-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    /* Adjust for spacing */
    gap: 10px;
    /* Space between buttons */
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    /* Center align */
}

/* Individual button styling */
.data-button {
    padding: 10px 20px;
    /* Matching padding */
    font-size: 16px;
    /* Matching font size */
    text-align: center;
    background-color: var(--main-button-color);
    /* Use the same variable as category button */
    color: var(--main-text-color);
    /* Same text color */
    border: none;
    border-radius: 5px;
    /* Same border-radius */
    cursor: pointer;
    width: 100%;
    /* Full width inside container */
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Smooth transition for hover effects */
}

/* Hover effect for the data buttons */
.data-button:hover {
    background-color: var(--main-button-hover);
    /* Same hover effect color */
}

/* Active state */
.data-button.active {
    background-color: var(--main-button-selected);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Add shadow for active state */
    color: white;
}


/*Information Signs*/
.sign-image {
    max-width: 150px;
    /* Adjust as needed */
    max-height: 150px;
    /* Adjust as needed */
    width: auto;
    height: auto;
    object-fit: cover;
    /* Optional: This will ensure the image maintains its aspect ratio */
}

.expanded-sign-container {
    padding: 15px;
    background-color: var(--main-bg-color); 
    border-radius: 0 0 12px 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: -17px; /* Overlap with button */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Style for each item within the expanded sign container */
.expanded-sign-item {
    width: 100%;
    margin-bottom: 15px; /* Space below each item */
}

/* Styles for the title field */
.expanded-sign-title {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    background-color: #fff; /* White background */
    box-sizing: border-box; /* Include padding and border in width */
    font-size: 16px; /* Font size */
    font-family: Arial, sans-serif; /* Font family */
    min-height: 40px; /* Optional: Set a minimum height if needed */
}

/* Styles for the description field */
.expanded-sign-description {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    background-color: #fff; /* White background */
    box-sizing: border-box; /* Include padding and border in width */
    font-size: 16px; /* Font size */
    font-family: Arial, sans-serif; /* Font family */
    overflow: hidden; /* Hide scrollbars */
    resize: none; /* Disable manual resizing */
}

/* Focus state for input fields */
.expanded-sign-title:focus,
.expanded-sign-description:focus {
    border-color: #007bff; /* Blue border on focus */
    outline: none; /* Remove default focus outline */
}

/* Container for image and label */
.image-wrapper {
    display: flex;
    align-items: flex-start; /* Align items at the top */
    margin-left: 20px;
    position: relative; /* Position relative for absolute children */
}

/* Label styling */
.image-label {
    margin-right: 15px; /* Space between label and image */
    margin-top: 60px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

/* Container for the currently selected image */
.selected-image-container {
    margin-right: 15px;
}

/* Styling for the selected image */
.expanded-sign-image {
    max-width: 75px;
    height: auto;
    margin: 15px;
    border-radius: 8px;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
}

/* Modal Overlay (Same) */
.infosign-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Slightly darker for more contrast */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

/* Modal Content */
.infosign-modal-content {
    background-color: var(--main-bg-color);
    padding: 20px;
    border-radius: 12px; /* Softer corners */
    width: 80%;
    max-width: 600px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow for depth */
    position: relative;
    max-height: 80vh; /* Ensures it doesn't exceed viewport height */
}

/* Modal Header */
.infosign-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

/* Container for the image grid */
.image-selector-container .available-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

/* Images in modal */
.image-option {
    background-color: white;
    border: 2px solid transparent;
    
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    width: 100px; /* Adjust size as needed */
    height: 100px;
    object-fit: cover; /* Keeps image proportions */
}

/* Hover effect */
.image-option:hover {
    border-color: #007bff;
}

/* Selected image styling */
.image-option.selected {
    background-color: #007bff; /* Blue background for the selected image */
    border-color: #0056b3; /* Darker blue border */
    color: white;
}


.image-button {
    position: relative;
    cursor: default;
    display: inline-block;
}

.image-button.edit-mode {
    cursor: pointer; /* Pointer cursor when in edit mode */
}

.image-button img {
    width: 100%; /* Adjust size as needed */
    height: auto;
    border-radius: 4px;
}

.image-button .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-button.edit-mode:hover .image-overlay {
    opacity: 1;
}


/*Collectables*/
.chapter-image-container {
    display: flex;
    place-content: center;
    margin-top: 20px;
    width: 100%;
}

.chapter-image {
    width: 175px;
    max-width: auto;
    height: 175px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h5 {
    margin-top: 2px;
    margin-bottom: 10px;
}

/* minigame */
  .activeTab {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .selectPrompt {
    margin-bottom: 20px;
    font-size: 18px;
    color: #666;
    text-align: center;
  }
  
  /* Grid Container */
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    width: 100%;
  }
  
  /* Common Styles for Palette */
  .palette {
    position: relative;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  /* Category Image Styles */
  .category-image .minigameimg {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
  }
  
  /* Item Image Styles */
  .item-image {
    padding: 10px; /* Padding for item images */
  }
  
  .item-image .minigameimg {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
  
  .palette:hover .overlay {
    opacity: 1; /* Show overlay on hover */
  }
  
  .overlay-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333; /* Adjust color as needed */
  }
  
  
  /* Create Form Styles */
  .createForm {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .createForm input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .createForm button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .createForm button:hover {
    background-color: #007bff;
    color: white;
  }

/* Overlay for minigame */
.minigame-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

/* Modal content for minigame */
.minigame-modal-content {
    background-color: var(--main-bg-color); /* Updated background color */
    border-radius: 12px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Modal Header */
.minigame-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

/* Modal Body */
.minigame-modal-body {
    margin-bottom: 15px;
}

/* Modal Footer */
.minigame-modal-footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-top: none; /* Remove horizontal line */
    padding-top: 10px;
}

/* Confirmation dialog overlay */
.minigame-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* Higher z-index to ensure it's above the modals */
}

/* Confirmation modal content */
.minigame-confirmation-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    width: 300px;
    text-align: center;
    z-index: 3001; /* Higher z-index to ensure it's above the overlay */
}

/* Confirmation buttons */
.minigame-confirmation-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.minigame-confirm-button, .minigame-cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.minigame-confirm-button {
    background-color: #e74c3c;
    color: #fff;
}

.minigame-cancel-button {
    background-color: #bdc3c7;
    color: #fff;
}
/* Label Styles */
.minigame-modal-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 0.9rem;
}

/* Input Fields */
.minigame-modal-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px; /* Space between fields */
}
  

/* StaticData */
.staticDataInputLineup {
    flex: 0 0 20%;
    font-weight: bold;
    margin-left: 20px;
}

.edit-StaticData input,
.edit-StaticData-field textarea {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-right: 90px;
}

.document-container {
    /* border: 1px solid #070707; */
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #BBC7B3;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.subfield-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.subfield-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-left: -20px;
}

.subfield-item label {
    min-width: 10px;
    font-weight: bold;
    margin-top: 10px;
    text-transform: uppercase;
}

.subfield-input {
    flex-grow: 1;
}

/* Admin Page Specific Styles */
.admin-page-header {
    font-size: 22px;
    font-weight: bold;
    color: var(--main-text-color); /* Use existing color variable */
    margin-bottom: 20px;
}

/* Admin table style to differentiate from other tables */
.admin-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.admin-table th,
.admin-table td {
    border: 1px solid #ddd; /* Standard light border color */
    padding: 12px;
    text-align: left;
    font-size: 14px;
    color: var(--main-text-color); /* Keep text color consistent */
}

.admin-table th {
    background-color: #f8f9fa; /* Light gray background for table headers */
    font-weight: bold;
    font-size: 16px;
    padding-left: 18px;
}

.admin-table tr:hover {
    background-color: #e9ecef; /* Light gray for hover effect */
}

/* Admin notification message style */
.admin-notification {
    padding: 12px;
    border: 1px solid #d4d4d4; /* Light border for separation */
    background-color: #e7f3ff; /* Light blue background for notification */
    color: #0056b3; /* Darker blue text for visibility */
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 14px;
}

/* Admin input field style */
.admin-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin: 0;
}

.admin-table-actions {
    display: flex; /* Align buttons horizontally */
    justify-content: center; /* Center them in the table cell */
    align-items: center; /* Align buttons vertically in the middle */
    gap: 8px; /* Add spacing between the buttons */
}

/* Admin form container */
.admin-form {
    background-color: #fdefdb;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

/* Admin page section header */
.admin-section-header {
    font-size: 18px;
    font-weight: bold;
    color: var(--main-text-color); 
    margin: 10px 0;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 5px;
}

.add-user-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #b6d3ff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.input-fields {
    display: flex;
    flex-direction: row; /* Arrange input fields in a row */
    gap: 15px; /* Space between fields */
    width: 100%; /* Make it take the full width of the container */
}

.user-list-container {
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #b6d3ff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
}

.actions-column {
    width: 100px;
    text-align: center;
}

.admin-table td.actions-column {
    padding-left: 5;
    padding-right: 5;
}

.account-container {
    display: flex;
    justify-content: flex-end; /* Pushes content to the right */
    align-items: center; /* Vertically aligns the text and button */
    width: 100%; /* Ensures the container takes full width */
}

.logout-button {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    background-color: var(--main-button-color); /* Default background color */
    color: var(--main-text-color); /* Text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between icon and text */
}

.logout-button:hover {
    background-color: var(--main-button-hover);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensures it's vertically centered in the viewport */
    background-color: #ececec;
}

.login-container {
    text-align: center; /* Center the content horizontally */
    margin-bottom: 150px;
    width: 100%;
    max-width: 600px;
}

.login-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--main-button-color);
    color: var(--main-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}

.login-button:hover {
    background-color: var(--main-button-hover);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-icon {
    color: green;
    font-size: 25px; 
    margin-right: 10px;
    vertical-align: middle;
}

.cms-logo-login {
    width: 300px;
    height: auto; 
    display: block;
    margin: 0 auto 20px auto;
}


.collectable-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
    padding: 20px; /* Indre margin */
    gap: 20px;
    margin: 20px 0;
    
}

.collectable-card {
    background-color: #fff;
    width: 200px; /* Fast bredde */
    height: 300px; /* Fast højde */
    display: flex;
    flex-direction: column; /* Sørger for, at billedet og navnet står vertikalt */
    align-items: center; /* Centrerer billedet og navnet horisontalt */
    justify-content: center; /* Centrerer indholdet vertikalt */
    padding: 0;
    text-align: center;
    margin: 0;
    border: 0px solid #ccc;
    border-radius: 0px;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.image-container {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Fylder hele containeren uden at forvrænge billedet */
    position: relative; /* For at overlayet dækker billedet */
    display: inline-block; /* Gør containeren kun så stor som indholdet */
}

.collectable-name {
    position: absolute;
    top: 0; /* Juster efter behov */
    left: 50%;
    transform: translateX(-50%); /* Centrerer teksten horisontalt */
    color: white; /* Vælg en farve, der kontrasterer med billedet */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent baggrund for bedre læsbarhed */
    padding: 5px; /* Tilføj lidt polstring */
    opacity: 0; /* Start med at skjule teksten */
    transition: opacity 0.3s ease; /* Giver en blød overgang */
   
}

.collectable-card:hover .collectable-name {
    opacity: 1; /* Gør teksten synlig ved hover */
}

.collectable-card:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Collectable image */
.collectable-image {
    width: 100%;
    height: auto;
}

.secondModal-content {
    background-color: var(--main-bg-color);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 50%; /* Tilpas bredden af modal */
}

.modal-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    object-fit: contain;
}

.collectable-card:hover .overlay {
    opacity: 1; /* Bliv synlig ved hover */
}
.gallery-grid-wrapper {
    max-height: 80vh;
    width: 100%;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    max-height: 100%;
    overflow-y: auto;
}

.gallery-image {
    width: 100%;
    height: auto;
    cursor: pointer;
    border: 2px solid #ccc;
    object-fit: cover;
}

.gallery-image:hover {
    border-color: #333;
}

.button-container {
    display: flex; /* Anvender Flexbox */
    justify-content: flex-start; /* Placerer knapperne til højre */
    gap: 10px; /* Tilføjer afstand mellem knapperne, juster efter behov */
}

.upload-label {
    display: flex;
    flex-direction: column;
}

.upload-label input[type="file"] {
    margin-top: 8px; /* Juster afstanden efter behov */
}

.bold-label {
    font-weight: bold;
}

.gallery-button {
    background-color: #dea411;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.collectabe-modal-input {
    width: 550px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px; /* Space between fields */
}

.collectable-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  
  .collectable-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
   /* Generelle knapper til second modal */
.secondModalButton {
    padding: 10px 15px; /* Indvendig margen for knapper */
    border: none; /* Ingen kantlinje */
    border-radius: 5px; /* Rundede hjørner */
    cursor: pointer; /* Pointer ved hover */
    transition: background-color 0.3s, transform 0.2s; /* Blød overgang */
    
}

/* Redigeringsknap */
.secondModalButton.toggle-editing {
    background-color: #5bc0de; /* Blå baggrund */
    color: white; /* Hvid tekst */
    margin-bottom: 20px; /* Afstand til indholdet */
    
}

.secondModalButton.toggle-editing:hover {
    background-color: #31b0d5; /* Mørkere blå ved hover */
}

/* Sletteknap */
.delete-image-button {
    background-color: #d9534f; /* Rød baggrund */
    color: white; /* Hvid tekst */
    position: absolute; /* Absolut positionering */
    top: 5px; /* Placering fra toppen */
    right: 5px; /* Placering fra højre */
    border: none; /* Ingen kantlinje */
    padding: 5px; /* Indvendig margen */
}

.delete-image-button:hover {
    background-color: #c9302c; /* Mørkere rød ved hover */
    transform: scale(1.05); /* Let zoom-effekt */
}

/* For at sikre at sletteknapperne ikke skubber billedet */
.gallery-image-container {
    position: relative; /* For at placere knapper i forhold til billedet */
}

.gallery-button {
    background-color: #47668e;
    color: white;
    padding: 13px 34px;
    height: 13px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 5px;
}

.upload-container {
    display: flex;
    align-items: flex-start; /* Juster indholdet, så det står i toppen */
}

.gallery-container {
    display: flex;
    flex-direction: column; /* Gør så teksten og knappen stables vertikalt */
    margin-left: 10px; /* Plads mellem de to sektioner */
}

.button-group-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-right: -11px;
}

.button-group {
    display: flex;
    
}