section {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.bgImg-container {

    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40%;
    height: 70%;

}

.custom-text-sec4 {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
  /*   margin:  "xs:1rem, sm: 2rem"; */
    height: 335px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 25px;
    z-index: 2;
}

.text-area-sec4 {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 95%;    
    padding-top: 10px;
    overflow: auto;
    text-align: left;
}

.windmill-container {
    position: absolute;
    bottom: 55px;
    right: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 20%;
    background-color: transparent;
    z-index: 0;

}

.bushes-container {
    position: absolute;
    bottom: -19px;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    background-color: transparent;

}

.bushes {
    width: 100%;
}

.prevNextIconsSec4 {

    height: 45px;
}

.contentDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.column3 img {
    height: 40px;
    margin-top:10px; 
}

.column2 img {
    height: 50px;
    margin-top:10px
}

.column1 {
    width: 33%;
    flex: 0.50;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: flex-end;
}
.column2 {
    width: 33%;
    flex: 0.50;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
}
.column3 {
    width: 33%;
    flex: 0.50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
@media (max-width: 820px) {
    .custom-text-sec4 {
        width: auto;
        margin-left: 7%;
        margin-right: 7%;   
        height: fit-content;
        min-height: 350px;     
    }
    .contentDiv {
        flex-direction: column;
        height: auto;
    }
}
@media (max-width: 1024px) {
    .image-4 {
        height: auto;
    }

    

    .column1,
    .column2,
    .column3 {
        flex: 1;
        width: 100%;
        height: auto;
        position: static;
        align-items: center;
        margin-left: 0;        

    }

    .column1 {
        margin-top: 150px;
    }

    .column2 {
        margin-top: 30px;
    }

    .column3 {
        margin-bottom: 30px;
    }

    .prevNextIconsSec4 {
        height: 50px;
    }

    .column1 img {
        height: 35px;
    }

    .column2 img {
        height: 50px;
    }

    .custom-text-sec4 {
        flex-direction: column;
        align-items: center;
        justify-content: center;        
    }

    .custom-text-sec4 h1 {
        display: flex;
        justify-content: center;
    }


    .bushes-container {
       
        width: 100%;

    }

    .bushes {
        width: auto;
        height: 100px;
    }



}

@media (max-width: 1180px) {
    
    .image-4 {
        height: auto;
    }
    .windmill-container {
        width: 0%;
        height: 0%;
        bottom: 0;
    }

    .contentDiv {
        flex-direction: column;
        height: auto;
    }

    .column1,
    .column2,
    .column3 {
        flex: 1;
        width: 100%;
        height: auto;
        position: static;
        align-items: center;
        margin-left: 0;
    }

    .column1 {
        margin-top: 140px;
    }

    .column2 {
        padding-top: 80px;
    }

    .column3 {
        padding-top: 100px;
        margin-bottom: 140px;
     
    }
    

}
