/* Importerer stylesheet med definerede farver */
@import './ComponentStyling.css';

.container {
    display: flex;
    height: 100vh; /* Full viewport height */
    margin: 0; /* Ensure there's no margin on the container */
    flex-direction: column; /* Align topbar and content vertically */
}

.topbar {
    display: flex;
    align-items: center;
    justify-content: center; /* Center items horizontally */
    background-color: var(--main-bg-color);
    padding: 10px 20px;
    /* color: var(--main-text-color); */
    height: 60px;
    position: fixed; /* Make the topbar fixed at the top */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001; /* Ensure it stays above other content */
    box-shadow: 0 4px 2px -2px gray; /* Optional: Add shadow for better separation */
}

.logo {
    position: absolute; /* Position logo absolutely */
    left: 20px; /* Adjust as needed */
    height: 75px;
    cursor: pointer; /* Make the logo clickable */
}


.account {
    position: absolute; /* Position account absolutely */
    right: 20px; /* Adjust as needed */
    color: rgb(0, 0, 0);
}

.sidebar {
    width: 200px;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    font-weight:bolder;
    padding-top: 20px;
    position: fixed; /* Keep it fixed on the left */
    top: 60px; /* Adjust top position to be under the topbar */
    bottom: 0;
    left: 0;
    overflow-y: auto; /* Handle overflow */
    z-index: 1000;
    border-right: none; /* Ensure no border on the right */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding if needed */
}

.navList {
    list-style-type: none;
    padding: 0;
    margin: 0; /* Ensure no margin on the list */
}

.navList li {
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-bottom: 1px solid #97A191;
}

.navList li:hover {
    background-color: var(--main-button-hover);
    color: var(--main-text-color);
}

.navList .selected {
    background-color: var(--main-button-selected); /* Highlight color */
    color: white; /* Text color for the selected item */
    font-weight: bold; /* Optional: make the text bold */
}

.mainContent {
    margin-left: 200px; /* Offset by the sidebar width */
    padding: 20px;
    flex-grow: 1; /* Take the remaining space */
    background-color: #ffffff;
    border-left: none; /* Ensure no border on the left */
    margin-top: 60px; /* Offset by the topbar height */
    overflow-y: auto; /* Handle content overflow */
}

.defaultMessage {
    font-size: 18px;
    color: #6c757d;
    text-align: center;
    margin-top: 50px;
}


