
.sec2 {
  height: 400px;
}
.h2{
  color:#0b2444 ;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {

  .h1{
    font-size: 1.5rem;
  }
  .sec2 {
    height: auto;
  }

  .textdiv{
    width: 100%;
   
  }
  .textdiv h2 {
    text-align: center;
    padding: 10px; 
    width: 100%;
  }
  .texth1{
    font-size: 1.5rem;

  }

}

@media (max-width: 768px) and (orientation: landscape) {

  .sec2 {
    height: auto;
  }
 
}