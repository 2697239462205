.header {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  right: 0;
  top: 20px;
  width: 500px;
  z-index: 1000;
  height: 55px;
  border-radius: 25px 0px 0px 25px;
}

.nav {
  display: flex;
  padding-right: 50px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  transition: max-height 0.3s ease-in-out;
  width: 100%;
  max-height: 0;
}

.nav-list {
  padding-top: 12px;
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin: 0;


  transition: color 0.3s ease-in-out;

}

.nav-item {
  font-size: 22px;
  color: #1544a8;

  
}

.nav-item:hover {
  color: #ee8d0e;


  transition: color 0.3s ease-in-out;
}

.active-link {
  transition: color 0.3s;
  text-decoration: none;
  color: #fff;
}

.active-link:hover {
  color: #ee8d0e;
}

.active-link {
  color: red;
}

.logo {
  font-size: 24px;
  margin-top: 15px;
  padding-bottom: 5px;
  color: #ffd00b;
  font-weight: 800;
  margin: 0 auto;
}

.burger-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: #1d0d77;
  
}

.burger-menu{
  display: none;
}


@media (max-width: 375px) {

  .header {
    width: 17%;
    justify-content: center;
  }
}

@media (max-width: 390px) {

  .header {
    width: 17%;
    justify-content: center;
  }

}

@media (max-width: 414px) {

  .header {
    width: 15%;
    justify-content: center;
  }
}


@media screen and (max-width: 900px) {
  .header {
    width: 15%;
    justify-content: center;
  }
  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    align-items: center;
    top: 60px;    
    overflow:hidden;
    transition: max-height 0.3s ease-in-out;
    width: 190px;
    height: 240px;
  }

  .nav.open {
    max-height: 1000px;
    height: fit-content;
    position: fixed; 
    z-index: 1; 
    top: 20px;     
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    overflow-x: hidden;   
    transition: 0.3s; 
    border-radius: 25px 0 0 25px;
    transition: max-height 0.3s ease-in-out, height 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }

  .nav-link {
    text-decoration: none !important;
    
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    text-decoration: none;
    width: 90px;
    transition: 0.3s;
    align-items: center;
    padding-bottom: 12px;
   
  }


  .burger-menu{
    display: block;
  }

  .burger-icon {
    display: block;
  }
}